<template>
  <div class="awardWiningSchools">
    <div class="flex-box">
      <div class="left">
        <el-button type="success" @click="addAwardWiningSchools">+新增</el-button>
        <!-- <el-button type="info">导入</el-button> -->
        <el-button @click="loadTemplate" type="info">下载导入模板</el-button>
        <el-upload action="/api/base/importAward.do"
                   :data="{type:3}"
                   class="display marginRightAndLeft"
                   :headers="uploadHeader"
                   name="uploadFile"
                   :on-success="uploadSuccess"
                   :show-file-list="false">
          <el-button type="info"
                     icon='el-icon-upload2'>导 入
          </el-button>
        </el-upload>
        <el-button @click="loadData" type="info"
                     icon='el-icon-download'>导 出
        </el-button>
        
      </div>
      <div class="flex-box">
        <!-- 学期 -->
        <el-select v-model="query.yearid"
          clearable
          placeholder="学期">
          <el-option v-for="semester in semesterList"
            :key="semester.id"
            :label="semester.name"
            :value="semester.id"></el-option>
        </el-select>
        <!-- 级别 -->
        <el-select class="picker" v-model="query.level"
          clearable
          placeholder="级别">
          <el-option v-for="level in levelList"
            :key="level.value"
            :label="level.name"
            :value="level.value"></el-option>
        </el-select>
        <!-- 奖状名称 -->
        <el-input v-model="query.name" class="input-box picker" placeholder="奖状名称"></el-input>

       <!-- 奖状类型
        <el-select v-model="level1"
          clearable
          placeholder="级别">
          <el-option v-for="level in levelList"
            :key="level.type"
            :label="level.awardName"
            :value="level.type"></el-option>
        </el-select>
        获奖学校
        <el-input class="input-box picker" v-model="awardName" placeholder="获奖名称"></el-input> -->
        <el-button class="picker" @click="loadAwardWiningSchools(1)" type="primary">查 询</el-button>
      </div>
    </div>
    <!-- table表格 -->
    <el-table
      :data="wardWiningSchoolsTable">
      <el-table-column
        label="学期"
        prop="semester"
        align="center"></el-table-column>
      <el-table-column
        label="获奖年度"
        prop="nd"
        align="center"></el-table-column>
        <el-table-column
        label="奖项名称"
        prop="name"
        align="center"></el-table-column>
        <el-table-column
        label="颁奖单位"
        prop="unit"
        align="center"></el-table-column>
        <el-table-column
        label="级别"
        prop="leaveStr"
        align="center">
        </el-table-column>
        <el-table-column
          label="证书扫描件等证明材料"
          align="center">
          <template slot-scope="scope">
            <el-image style="width: 100px; height: 100px"
                      v-if="scope.row.url"
                      :src="scope.row.photoUrl"
                      :preview-src-list="scope.row.photoUrlList">
            </el-image>
          </template>
        </el-table-column>
        <el-table-column
        label="时间"
        prop="opertime"
        align="center"></el-table-column>
        <el-table-column
        label="操作"
        width="150px"
        prop="course"
        align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="editAwardWiningSchools(scope.row)">编辑
            </el-button>
            <el-button
              type="danger"
              size="mini"
              @click="deleteAwardWiningSchools(scope.row.id)">删除
            </el-button>
          </template>
        </el-table-column>
    </el-table>
    <el-pagination layout="prev,pager,next"
                    background
                   :page-count="page.pageCount"
                   :page-size="page.currentPage"
                   @current-change="loadAwardWiningSchools"></el-pagination>
    <!-- 添加获奖学校与编辑获奖学校对话框 -->
    <el-dialog
      :title="awardWiningSchoolsForm.managerid ? '编辑获奖学校' : '添加获奖学校'"
      :visible.sync="awardWiningSchoolsDialogVisible"
      width="600px">
      <div class="center">
        <el-form
          :model="awardWiningSchoolsForm"
          ref="awardWiningSchoolsFormRef"
          label-width="100px"
          :rules="awardWiningSchoolsRules">
          <!-- 学期 -->
          <el-form-item label="学期：" prop="yearid">
            <termSelector :yearid1="awardWiningSchoolsForm.yearid" @change="termChange"></termSelector>
          </el-form-item>
          <!-- 获奖年度 -->
          <el-form-item label="获奖年度：" prop="nd">
              <el-date-picker
                v-model="awardWiningSchoolsForm.nd"
                type="year"
                placeholder="请选择年份"
                value-format="yyyy">
            </el-date-picker>
          </el-form-item>
          <!-- 奖项名称 -->
          <el-form-item label="奖项名称：" prop="name">
            <el-input v-model="awardWiningSchoolsForm.name"></el-input>
          </el-form-item>
          <!-- 单位 -->
          <el-form-item label="颁奖单位：" prop="unit">
            <el-input v-model="awardWiningSchoolsForm.unit"></el-input>
          </el-form-item>
          <!-- 级别 -->
          <el-form-item label="级别：" prop="level">
            <el-select v-model="awardWiningSchoolsForm.level"
              clearable
              placeholder="奖状类型">
              <el-option v-for="level in levelList"
                :key="level.value"
                :label="level.name"
                :value="level.value"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="证书扫描件等证明材料：">
            <el-upload
              class="upload-demo"
              list-type="picture"
              action="/api/base/uploadByOss.do"
              :headers="{Authorization:token}"
              :limit="1"
              :on-remove="onRemoveFile"
              ref="upload"
              :on-success="enclosureUploadSuccess"
              :data="{path:'awardWiningSchools',name:''}"
              :show-file-list="true">

              <el-button size="small" type="primary">点击上传</el-button>
              <div v-if="awardWiningSchoolsForm.url" slot="tip" class="el-upload__tip">已上传附件，可下载附件，也可重新上传</div>
            </el-upload>
            <el-button type="primary"
                     class="load-file"
                     @click="loadFile"
                     v-if="awardWiningSchoolsForm.url">下载附件</el-button>
          </el-form-item>

        </el-form>
      </div>
      <!-- 确认与取消 -->
      <span slot="footer" class="dialog-footer">
          <el-button @click="awardWiningSchoolsDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="addAwardWiningSchoolsConfirm">确 定</el-button>
        </span>
    </el-dialog>
  </div>
</template>
<script>
import termSelector from "@/components/selectors/termSelector";
export default {
  name: "awardWiningSchools",
  components: {termSelector},
  data(){
    return {
      token: sessionStorage.getItem("token"),
      uploadHeader: { Authorization: sessionStorage.getItem("token") },

      queryType:'',
      queryYear:'',
      page: {pageCount: 1, currentPage: 0},
      // 添加获奖学校的表单
      awardWiningSchoolsForm:{
        name:'', 
        unit:'',
        level:'',
        nd:'',
        yearid:'',
        url:''
      },
      // 添加获奖学校的表单验证规则对象
      awardWiningSchoolsRules: {
        name: [{required: true}],
        unit: [{required: true}],
        level: [{required: true}],
        nd: [{required: true}],
        yearid: [{required: true}],
        url: [{required: true}]
      },
      // 添加获奖学校的表单的显示与隐藏
      awardWiningSchoolsDialogVisible:false,
      // 获奖学校列表
      wardWiningSchoolsTable:[],
      teachersList:[],
      // levelList:[
      //   {type:0,awardName:'特等奖'},
      //   {type:1,awardName:'一等奖'},
      //   {type:2,awardName:'二等奖'}
      // ],
      level1:'',
      certificateTypeList:[
        {type:1,certificateName:'教务教学管理'},
        {type:2,certificateName:'常规德育管理'},
        {type:3,certificateName:'大队部管理'}
      ],
      awardName:'',
      yearid1:'',
      // 学期
      semesterList:JSON.parse(sessionStorage.getItem('semesterList')),
      // 级别列表
      levelList:[
        {value:1,name:'国家'},
        {value:2,name:'省'},
        {value:3,name:'市'},
        {value:4,name:'校'},
      ],
      // 查询
      query:{
        yearid:'',
        name:'',
        level:''
      }
    }
  },
  created() {
    this.loadAwardWiningSchools(1)
  },
  methods:{
    enclosureUploadSuccess(res, file) {
      this.awardWiningSchoolsForm.url = file.response.message;
    },
    loadFile() {
      window.open(this.$fileUploadDomain + this.awardWiningSchoolsForm.url);
    },
    // 文件移除的钩子
    onRemoveFile(res, file) {
      this.awardWiningSchoolsForm.url = "";
    },
    // 导入
    uploadSuccess(response, file, fileList) {
      if (response.type === 1) {
        this.$message({
          type: "success",
          message: "已上传文件",
        });
        this.loadAwardWiningSchools(1);
      } else {
        this.$message({
          type: "warning",
          message: response.message,
        });
      }
    },
    loadData(){
      const that = this;
      this.$postFile(`base/exportAwardSchool.do`)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "获奖学校数据";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    // 导出课获奖学校
    loadTemplate() {
      const that = this;
      this.$postFile(`/base/exportAwardMoBan.do?type=3`)
        .then((res) => {
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel;",
          });
          const a = document.createElement("a");
          // 生成文件路径
          let href = window.URL.createObjectURL(blob);
          a.href = href;
          a.download = "获奖学校模板";
          // 利用a标签做下载
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          window.URL.revokeObjectURL(href);
        })
        .catch((error) => {
          that.$message({
            type: "warning",
            message: error.message,
          });
        });
    },
    termChange(value){
      this.awardWiningSchoolsForm.yearid = value
      // console.log(value)
    },
    // 获取初始页面数据
    loadAwardWiningSchools(page){
      const that = this;
      let data = {
        indexNo: page,
        // level:this.level1,
        // name:this.awardName,

        yearid:this.query.yearid,
        name:this.query.name,
        level:this.query.level,
      };
      this.$post("/base/queryAwardSchoolList.do", data)
      .then((res) => {
        that.page.currentPage = page;
        that.page.pageCount = res.data.pageCount;
        console.log(res.data.rows)
        res.data.rows.forEach(item => {
          item.opertime = item.opertime.slice(0,10)+' '+item.opertime.slice(11,16)
          
          item.photoUrl = that.$fileUploadDomain + item.url;
          item.photoUrlList = [];
          item.photoUrlList.push(that.$fileUploadDomain + item.url);
        })
        that.wardWiningSchoolsTable = res.data.rows;
        console.log(that.wardWiningSchoolsTable)
      })
      .catch((err) => {
        that.$message({
        type: "warning",
        message: err.message,
        });
      });
    },
    // 点击添加获奖学校按钮
    addAwardWiningSchools(){
      this.awardWiningSchoolsDialogVisible = true
      this.$nextTick(() => {
        this.$refs.awardWiningSchoolsFormRef.resetFields()
        this.$refs.upload.clearFiles();
        this.awardWiningSchoolsForm.id = ''
        // this.awardWiningSchoolsForm.yearid = ''
        this.awardWiningSchoolsForm.url = ''
        
      })
    },
    // 点击编辑获奖学校按钮
    editAwardWiningSchools(row){
      // 显示对话框
      this.awardWiningSchoolsDialogVisible = true
      // 把数据赋值给编辑表单
      this.$nextTick(()=>{
        this.awardWiningSchoolsForm.id = row.id
        this.awardWiningSchoolsForm.name = row.name
        this.awardWiningSchoolsForm.unit = row.unit
        this.awardWiningSchoolsForm.level = row.level
        this.awardWiningSchoolsForm.nd = row.nd.toString()
        this.awardWiningSchoolsForm.yearid = row.yearid
        this.awardWiningSchoolsForm.url = row.url
        this.$refs.upload.clearFiles();
      })
      // this.yearid1 = row.yearid
    },
    // 点击删除获奖学校按钮
    deleteAwardWiningSchools(id){
      const that = this;
      this.$confirm("是否删除学生获奖?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
      .then(() => {
        this.$post("/base/deleteAwardSchool.do", { id })
        .then((res) => {
          that.$message({
            type: "success",
            message: "删除成功!", 
          });
          that.loadAwardWiningSchools(that.page.currentPage);
        })
        .catch((err) => {
          that.$message.error(err.message)
        });
      })
      .catch(() => {
        this.$message({
        type: "info",
        message: "已取消删除",
        });
      });
    },
    // 弹出框确定，提交数据
    addAwardWiningSchoolsConfirm(){
      // 表单预校验
      this.$refs.awardWiningSchoolsFormRef.validate(validate => {
        // 校验通过
        if(validate){
          // 编辑获奖学校表单提交
          if(this.awardWiningSchoolsForm.id){
            this.$post('/base/updateAwardSchool.do',this.awardWiningSchoolsForm)
            .then(res => {
              console.log(res)
              this.awardWiningSchoolsDialogVisible = false;
              // 更新当前页数据列表
              this.loadAwardWiningSchools(this.page.currentPage)
              this.$message({
                type:'success',
                message:'编辑成功！'
              })
            })
            return
          }
          // 添加获奖学校表单提交
          const data = {
            name:this.awardWiningSchoolsForm.name,
            unit:this.awardWiningSchoolsForm.unit,
            level:this.awardWiningSchoolsForm.level,
            nd:this.awardWiningSchoolsForm.nd,
            yearid:this.awardWiningSchoolsForm.yearid,
            url:this.awardWiningSchoolsForm.url
          }
          console.log(data)
          this.$post('/base/insertAwardSchool.do',data)
          .then(res => {
            this.awardWiningSchoolsDialogVisible = false;
            this.loadAwardWiningSchools(1);
            this.$message({
              type:'success',
              message:'添加成功！'
            })
            console.log(res)
          })
          .catch((err) => {
            // that.$message.error(err.message.data);
          });
        }
      })
    }
  }
}
</script>
<style scoped>
.left{
  display: flex;
}

.display {
  display: inline-block;
}

.marginRightAndLeft {
  margin-right: 10px;
  margin-left: 10px;
}

.input-box {
  width: 180px;
  margin: 0 5px;
}

.flex-box {
  display: flex;
  justify-content: space-between;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker {
  margin-left: 10px;
}
</style>